
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Helpers } from '@/services/helpers';
import { QUIZ_VARIANTS } from '@/services/constants';

export default {
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
    sliders: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters('quiz', ['getHeaderStatus', 'getProductType']),
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
    devMode() {
      return Vue.config.devtools;
    },
    loggedin() {
      return Helpers.loggedin();
    },
    isBodyNutritionButton() {
      return (
        this.isBodyNutritionTour ||
        this.isWeightLossMonthlyTour ||
        this.isNutritionWeeklyStartTour ||
        this.isNutritionKetoTour ||
        this.shouldReplicateWeightLossMonthlyTour
      );
    },
    homeUrl() {
      const variantsMap = {
        [QUIZ_VARIANTS.DEFAULT]: 'home',
        [QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: 'homePilates',
        [QUIZ_VARIANTS.WALL_PILATES]: 'homeFitOver40',
        [QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: 'homePilates',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE]: 'homePilatesChairChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY]: 'homePilatesFlatBelly',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: 'homePilatesChallengeSomatic',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_UPDATED_TARGET_ZONE_STEP]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_NOW]: 'homePilatesNow',
        [QUIZ_VARIANTS.BODY_NUTRITION]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE]: 'homeNutrition28DayNoSugarChallenge',
        [QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE]: 'homeNutrition28DayWeightLossChallenge',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.RELOAD_MODAL]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.NUTRITION_WEEKLY_START]: 'homeNutritionWeeklyStart',
        [QUIZ_VARIANTS.NUTRITION_KETO]: 'homeNutritionKeto',
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL]: 'homeNutritionKetoMeal',
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: 'homeNutritionKetoWeekly',
        [QUIZ_VARIANTS.NUTRITION_KETO_AGE]: 'homeNutritionKetoWeekly',
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: 'homeNutritionKetoWeekly',
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY]: 'homeNutritionKetoWeeklyMeal',
        [QUIZ_VARIANTS.FAST_WEIGHT_LOSS]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.MACRO_DIET]: 'homeMacroDietForWomen',
        [QUIZ_VARIANTS.OPTIMAL_WEIGHT]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.HEALTH_FOOD_IMPACT]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.METABOLIC_RENEWAL]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.CHOLESTEROL_CONTROL]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_SECRET]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: 'homeYogaChallenge',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1]: 'homeYogaChallenge',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2]: 'homeYogaChallenge',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'homeYogaChallengeGenericOthers',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_REMOVE_ATHLETIC_TYPE]: 'homeYogaChallenge',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_LANDING_PAGE_SENIOR_V1]: 'homeYogaChallenge',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_LANDING_PAGE_SENIOR_V2]: 'homeYogaChallenge',
        [QUIZ_VARIANTS.SOMATIC_YOGA]: 'homeSomaticYoga',
        [QUIZ_VARIANTS.SOMATIC_EXERCISES]: 'homeSomaticExercises',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V1]: 'homeNutritionMonthlyStart',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V2]: 'homeNutritionMonthlyStart',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS]: 'homeNutritionMonthlyGenericOthers',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US]: 'homePilatesChallengeGenericUs',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'homePilatesChallengeGenericOthers',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US]: 'homeYogaChallengeGenericUs',
        [QUIZ_VARIANTS.PILATES_28_DAY]: 'homeReverseFitness',
        [QUIZ_VARIANTS.PILATES_28_D]: 'homeReverseFitness',
      };

      return variantsMap[this.getQuizVariant] || variantsMap[QUIZ_VARIANTS.DEFAULT];
    },
    getHamBurgerIcon() {
      return [
        QUIZ_VARIANTS.BODY_NUTRITION,
        QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE,
        QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US,
        QUIZ_VARIANTS.RELOAD_MODAL,
        QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        QUIZ_VARIANTS.WALL_PILATES,
        QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE,
        QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
        QUIZ_VARIANTS.PILATES_NOW,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
        QUIZ_VARIANTS.NUTRITION_KETO,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY,
        QUIZ_VARIANTS.FAST_WEIGHT_LOSS,
        QUIZ_VARIANTS.MACRO_DIET,
        QUIZ_VARIANTS.OPTIMAL_WEIGHT,
        QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40,
        QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING,
        QUIZ_VARIANTS.HEALTH_FOOD_IMPACT,
        QUIZ_VARIANTS.METABOLIC_RENEWAL,
        QUIZ_VARIANTS.CHOLESTEROL_CONTROL,
        QUIZ_VARIANTS.WEIGHT_LOSS_SECRET,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE,
        QUIZ_VARIANTS.SOMATIC_YOGA,
        QUIZ_VARIANTS.SOMATIC_EXERCISES,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V1,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V2,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS,
        QUIZ_VARIANTS.PILATES_28_DAY,
      ].includes(this.getQuizVariant)
        ? 'icon-hamburger-menu'
        : 'icon-hamburger';
    },
    coloredHeader() {
      return [
        QUIZ_VARIANTS.PILATES_NOW,
        QUIZ_VARIANTS.NUTRITION_KETO,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V1,
        QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V2,
      ].includes(this.getQuizVariant);
    },
  },
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
  methods: {
    navigationHandler() {
      this.isOpen = !this.isOpen;
    },
    logout() {
      Helpers.logout();
      window.location.href = this.$getUrl('home');
    },
    handleDefaultState() {
      this.$router.push('/quiz/start');
    },
  },
};
